import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import links from '@api/links'
import checkMobile from '@helpers/isMobile'
import redirect from '@helpers/redirect'
import analytics, { AnalyticsEventId } from '@src/Analytics'
import { snackbarOperations } from '@store/modules/Snackbar'
import { animationOperations } from '@store/modules/Animation'
import { AuthKind, userSelectors } from '@store/modules/User'
import AuthWrapper from '@components/auth/AuthWrapper'
import AuthenticationForm from '@components/sign-up/AuthenticationForm'
import { useTranslation } from '@src/i18n'
import LocalSettings from '@layouts/LocalSettings'
import Box from '@base/Box'

export type ContentProps = {
  title?: string
  subtitle?: string
  redirectTo?: string
  error?: string
  email?: string
  therapistId?: string
  customLinks?: {
    signup: any
    login: any
  }
  onComplete?: () => void
  onGoogleSignIn?: () => void
}

export const Login = ({
  redirectTo,
  error,
  email,
  therapistId,
  customLinks,
  onComplete,
  onGoogleSignIn,
}: ContentProps) => {
  const eventId: AnalyticsEventId = 'login' as AnalyticsEventId
  const dispatch = useDispatch()

  useEffect(() => {
    error && dispatch(snackbarOperations.open(error, 'error'))
    dispatch(animationOperations.push('mail'))
  }, [])

  useEffect(() => analytics.pushEvent(eventId), [eventId])

  const router = useRouter()
  const { coupon } = router.query

  const { t } = useTranslation()

  return (
    <AuthWrapper>
      <AuthenticationForm
        title={t('meta.login.title')}
        email={email}
        kind={AuthKind.Login}
        redirectTo={redirectTo || links.dashboard}
        coupon={coupon as string | undefined}
        therapistId={therapistId}
        onComplete={onComplete}
        onGoogleSignIn={onGoogleSignIn}
        customLinks={customLinks}
      />
      <Box textAlign={{ xs: 'center', sm: 'right' }} my={2}>
        <LocalSettings />
      </Box>
    </AuthWrapper>
  )
}

Login.getInitialProps = ctx => {
  const state = ctx.store.getState()
  const userState = userSelectors.getSelf(state)

  // Redirect logged user to homepage
  if (userSelectors.isLoggedIn(userState)) {
    redirect(ctx, links.home)
    return {}
  }
  const isMobile = checkMobile({ req: ctx.req })
  const { error, redirectTo, email } = ctx.query
  return { error, redirectTo, email, isMobile, namespacesRequired: ['web', 'terapio'] }
}

export default Login
