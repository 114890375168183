import { ReactNode } from 'react'
import Section from '@components/flow/Section'
import Head from '@common/Head'
import { useTranslation } from '@src/i18n'
import { AuthKind } from '@store/modules/user/Types'

type Props = {
  title: string
  subtitle?: string
  kind: AuthKind
  children?: ReactNode
}

const AuthSection = ({ title, subtitle, kind, children }: Props) => {
  const { t } = useTranslation()
  const prefix = kind === AuthKind.Login ? 'meta.login' : 'meta.signUp'

  return (
    <Section title={title} subtitle={subtitle}>
      <Head title={t(`${prefix}.title`)} description={t(`${prefix}.description`)} />
      {children}
    </Section>
  )
}

export default AuthSection
