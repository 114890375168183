import AuthForm from '@pages/auth/AuthForm'
import React, { Dispatch, SetStateAction } from 'react'
import AuthSection from '@components/sign-up/AuthSection'
import { ContentProps } from '@pages/signUp'

export type SignUpCredentials = {
  identifier: string
  coupon: string
}

type SignUpFormType = {
  onSubmit?: () => void
  setSignupCredentials?: Dispatch<SetStateAction<SignUpCredentials>>
}

const AuthenticationForm = ({
  title,
  subtitle,
  kind,
  redirectTo,
  email,
  coupon,
  therapistId,
  customLinks,
  onComplete,
  onGoogleSignIn,
  onSubmit,
  setSignupCredentials,
}: ContentProps & SignUpFormType) => {
  return (
    <AuthSection title={title} subtitle={subtitle} kind={kind}>
      <AuthForm
        key={kind}
        email={email}
        kind={kind}
        redirectTo={redirectTo}
        formSubmitted={onSubmit}
        coupon={coupon as string | undefined}
        therapistId={therapistId}
        setSignupCredentials={setSignupCredentials}
        onComplete={onComplete}
        onGoogleSignIn={onGoogleSignIn}
        customLinks={customLinks}
      />
    </AuthSection>
  )
}

export default AuthenticationForm
