import { useRouter } from 'next/router'
import { useTranslation } from '@src/i18n'
import links from '@api/links'

import Wrapper from '@components/flow/Wrapper'

type Props = {
  children: any
}

const AuthWrapper = ({ children }: Props) => {
  const router = useRouter()
  const { t } = useTranslation()

  const redirectHome = async () => {
    await router.push(links.home)
  }

  return (
    <Wrapper
      goBackProps={{ label: t('auth.wrapper.goBackLarge'), mobileLabel: t('auth.wrapper.goBackSmall') }}
      closeDialogProps={{
        title: t('auth.wrapper.title'),
        text: t('auth.wrapper.text'),
        quitText: t('auth.wrapper.goAway'),
        closeText: t('auth.wrapper.continue'),
        handleQuit: redirectHome,
      }}
      showLine
    >
      {children}
    </Wrapper>
  )
}

export default AuthWrapper
